import req from '@/api/request'

// 枚举
export const enumList = (enumName) => {
  return req().get(`/base/api/sys/enum/view?name=${enumName}`)
}

/**
 * 课程分类
 * @returns {*}
 */
export const categoryLists = (data) => {
  return req().get(`/base/api/shop/category/list?categoryType=${data.categoryType ? data.categoryType : 1}`)
}

/**
 * 题库分类
 * @returns {*}
 */
export const categoryListS = (data) => {
  return req().get(`/base/api/shop/category/list?categoryType=${data.categoryType ? data.categoryType : 7}`)
}

/**
 * 题库二级分类
 * @param data
 * @returns {*}
 */
export const childrenLists = (data) => {
  return req().post(`base/api/shop/category/children`, data)
}

/**
 * 用户收藏添加
 * @param data
 * @returns {*}
 */
export function collectionUserSave (data) {
  return req().post('/scm/auth/collection/user/save', data)
}

/**
 * 用户收藏删除
 * @param data
 * @returns {*}
 */
export function collectionUserDelete (data) {
  return req().delete('/scm/auth/collection/user/delete', { data })
}

/**
 * 获取平台信息
 * @returns {*}
 */
export function shopDataInfo (params) {
  return req().get('/base/api/shop/website/domain', { params })
}

export function shopAgreement () {
  return req().get('/base/api/shop/agreement')
}

// 获取授权地址
export function getWxConfig (data) {
  return req().post('/base/api/wx/op/get/three/authorization/url', data)
}

// 绑定授权
export function bindAuthInfo (data) {
  return req().post('/base/api/wx/op/bind/authorization/info', data)
}

export function getWxAuthLoginUrl (data) {
  return req().post('/base/api/shop/user/get/wx/login/auth/url', data)
}

export function wxLogin (data) {
  return req().post('/base/api/shop/user/wx/authorized/login', data)
}

export function wxBinding (data) {
  return req().post('/base/api/shop/user/wx/authorized/bind', data)
}

export function checkPhoneExist (data) {
  return req().post('/base/api/shop/user/check/phone/exist', data)
}

export function dissociatedWechat () {
  return req().put('/base/auth/shop/user/unbind/wx')
}

export function bindingAuthWx (data) {
  return req().post('/base/auth/shop/user/bind/wx', data)
}

// 字典
export function getSignList(data) {
  return req().post('/base/api/sys/dict/list',data)
}